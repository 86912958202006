<style scoped>
@media (max-width: 1024px) {
  .c-sm-force-text-left {
    text-align: left !important;
  }
}
.c-noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
<script>

import {get_acsrf_token} from "@/methods";

import CopyableText from "@/components/CopyableText";
import {HalfCircleSpinner} from "epic-spinners";
import {mapGetters} from "vuex";
import {InvoiceState, PaymentGateway} from "@/enums";
import {parseDate} from "echarts/lib/util/number";
import scrollableLegendView from "echarts/src/component/legend/ScrollableLegendView";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    HalfCircleSpinner
  },
  validations: {

  },
  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId',
      'getDTLocale'
    ]),
  },
  methods: {
    scrollableLegendView,
    async retrieveInvoices() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/account/invoices`, {
          method: 'GET',
          credentials: 'include'
        });
        if (response.ok) {
          let data = await response.json();
					this.notices = data.notices;
          this.invoices = data.invoices;
					if(this.invoices.length !== 0) {
						this.csvTimeframe.startDate = this.csvTimeframe.defaultStartDate = this.getMinDate();
						this.csvTimeframe.endDate = this.csvTimeframe.defaultEndDate = this.getMaxDate();
					} else {
						this.csvTimeframe.startDate = this.shortDateFormat(new Date());
						this.csvTimeframe.endDate = this.shortDateFormat(new Date());
					}

          this.ready = true;
        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
    async invokeInvoiceSettlements() {
      this.processing = true;

      try {
        let acsrf_token = await get_acsrf_token();
        let payload = {
          acsrf_token: acsrf_token,
        };

        let url = new URL(process.env.VUE_APP_ROOT_API + `v1/account/payment-gateway/settle-outstanding`);
        let response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        });

        if (!response.ok) {
          if (response.status === 400) {
            let data = await response.json();
            if (data.error === 'no-payment-method') {
              this.processing = false;
              let swal = await this.$swal({
                icon: 'warning',
                title: this.$t('billing.invoices.settle.error.payment_method')
              });
              await this.$emit('childNavigate', {component: 'PaymentMethods'});
            } else throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          } else if (response.status === 429) {
            this.processing = false;
            this.$swal({
              icon: 'warning',
              text: this.$t('error.server.ratelimit.action'),
              title: this.$t('error.server.ratelimit.title')
            });

          } else
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }

      } catch (error) {
        console.log(`[ERROR] ${error}`);
        this.processing = false;
        this.$swal({
          icon: 'error',
          text: this.$t('billing.invoices.settle.error.generic')
        });
      }
    },
    async invoiceSettlementResponse(response) {
      this.processing = false;
      this.ready = false;

      if (response.status) {
        this.$swal({
          icon: 'success',
          title: this.$t('billing.invoices.settle.success.title'),
          text: this.$t('billing.invoices.settle.success.message')
        });
      } else if (response.status === null) {
        this.$swal({
          icon: 'info',
          text: this.$t('billing.invoices.settle.error.none')
        });
      } else {
        this.$swal({
          icon: 'error',
          text: this.$t('billing.invoices.settle.error.generic'),
          title: response.message
        });
      }

      await new Promise(resolve => setTimeout(resolve, 5000));
      await this.retrieveInvoices();
    },
    async processPaymentGatewayRequirements(data) {
      switch (data.gateway) {
        case PaymentGateway.STRIPE: {
          let result = await this.stripeObject.confirmCardPayment(
              data.client_secret,
              {
                payment_method: data.payment_method_id
              }
          );
          if (result.error) {
            this.$swal({
              icon: 'error',
              text: this.$t('billing.payment.confirm.error')
            });
            this.processing = false;
          }
          break;
        }
      }
    },
    async downloadCSV() {
      this.exportInProgress = true;
      try {
        const response = await fetch(
            `${this.apiBase}v1/account/invoices/csv?invoice_id=${this.csvDetails.invoice_id.value}&created_at=${this.csvDetails.created_at.value}&amount=${this.csvDetails.total.value}&charged=${this.csvDetails.charged.value}&state=${this.csvDetails.state.value}&charged_at=${this.csvDetails.charged_date.value}&fulfilled=${this.csvDetails.fulfilled.value}&fulfilled_at=${this.csvDetails.fulfilled_date.value}&provider=${this.csvDetails.provider.value}&tax=${this.csvDetails.tax.value}&startDate=${Date.parse(this.csvTimeframe.startDate)}&endDate=${Date.parse(this.csvTimeframe.endDate)}`,
            {
              method: 'GET',
              credentials: 'include'
            }
        );
        if (!response.ok){
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
        const blob = new Blob([await response.blob()], {type: "text/csv"});
        const fileURL = window.URL.createObjectURL(blob);
        const fURL = document.createElement('a');
        fURL.href = fileURL;
        fURL.setAttribute('download', `CFTools Invoices_${this.shortDateFormat(new Date())}.csv`);
        document.body.appendChild(fURL);
        fURL.click();
        this.exportInProgress = false;
        this.$bvModal.hide('csvModal')
        this.$swal(this.$t('billing.invoices.csv.success'), this.$t('billing.invoices.csv.success_popup'), "success");
      } catch (error) {
          this.exportInProgress = false;
          this.$bvModal.hide('csvModal')
          console.error(`[ERROR]: ${error}`);
          this.$swal(this.$t('billing.invoices.csv.error'), this.$t('billing.invoices.csv.error_popup'), "error")
      }
    }
  },
  created() {
    try {
      this.$socket.client.on('billing:gateway:approve', this.processPaymentGatewayRequirements);
      this.$socket.client.on('billing:invoice:settlement', this.invoiceSettlementResponse);
    } catch (e) {
      // nothing
    }
  },
  async mounted() {
    await this.retrieveInvoices();
  },
  destroyed() {
    try {
      this.$socket.client.off('billing:gateway:approve', this.processPaymentGatewayRequirements);
      this.$socket.client.off('billing:invoice:settlement', this.invoiceSettlementResponse);
    } catch (e) {
      // nothing
    }
  },
  data() {
    return {
      apiBase: process.env.VUE_APP_ROOT_API,
      InvoiceState:InvoiceState,
      ready: false,
      error: false,
      processing: false,
      invoices: [],
	    notices: [],
      sortBy: "created_at",
      sortDesc: true,
      currentPage: 0,
      fields: [
        {
          key: "state",
          sortable: true,
          label: this.$t('billing.invoices.headers.state')
        },
        {
          key: "created_at",
          sortable: true,
          label: this.$t('billing.invoices.headers.date')
        },
        {
          key: "invoice_id",
          sortable: true,
          label: this.$t('billing.invoices.headers.invoice_id')
        },
        {
          key: "amount",
          sortable: true,
          label: this.$t('billing.invoices.headers.amount')
        },
        {
          key: "charged",
          sortable: false,
          label: this.$t('billing.invoices.headers.charged')
        },
        {
          key: "fulfillment",
          sortable: false,
          label: this.$t('billing.invoices.headers.fulfilled')
        },
        {
          key: "document",
          sortable: false,
          label: this.$t('billing.invoices.headers.document')
        },
        {
          key: 'actions',
          sortable: false,
          label: ''
        }
      ],
      tableData: [],
      csvDetails: {
        invoice_id: {
          value: true,
          id: 'invoice_id',
          label: 'billing.invoices.csv.invoice_id'
        },
        created_at: {
          value: true,
          id: 'created_at',
          label: 'billing.invoices.csv.creation_date'
        },
        total: {
          value: true,
          id: 'amount',
          label: 'billing.invoices.csv.charge_amount'
        },
        tax: {
          value: true,
          id: 'tax',
          label: 'billing.invoices.csv.tax'
        },
        charged_date: {
          value: true,
          id: 'charged_date',
          label: 'billing.invoices.csv.charge_date'
        },
        state: {
          value: true,
          id: 'state',
          label: 'billing.invoices.csv.status'
        },
        provider: {
          value: true,
          id: 'billing_provider',
          label: 'billing.invoices.csv.billing_provider'
        },
        charged: {
          value: true,
          id: 'charged',
          label: 'billing.invoices.csv.charged'
        },
        fulfilled: {
          value: true,
          id: 'fulfilled',
          label: 'billing.invoices.csv.fulfillment'
        },
        fulfilled_date: {
          value: true,
          id: 'fulfilled_date',
          label: 'billing.invoices.csv.fulfillment_date'
        }
      },
      csvTimeframe: {
        defaultStartDate: null,
        defaultEndDate: null,
        startDate: null,
        endDate: null
      },
      exportInProgress: false,
      shortDateFormat(date) {
				console.log(`Date`, date);
        const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
        const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
        return `${year}-${month}-${day}`;
      },
      getMinDate() {
        return this.shortDateFormat(new Date(Math.min.apply(Math, this.invoices.map(function(i) { return parseDate(i.created_at); }))))
      },
      getMaxDate() {
        return this.shortDateFormat(new Date(Math.max.apply(Math, this.invoices.map(function(i) { return parseDate(i.created_at); }))));
      }
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <template v-if="invoices.length === 0">
        <div class="col-lg justify-content-center">
          <div  class="info-component text-center align-middle mt-auto mb-auto">
            <div class="info-component-space">
              <i class="fad fa-empty-set info-component-icon"></i>
            </div>
            <div class="row" style="margin-top: 20px;">
              <div class="col-lg-12">
                <h3 v-b-tooltip.hover title="Wow such empty 👻">{{$t('invoices.empty.title')}}</h3>
                <h5 class="text-muted">{{$t('invoices.empty.message')}}</h5>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
	      <template v-if="notices">
		      <div class="row justify-content-center">
		      <template v-if="notices.includes('paypal_express_unavailable')">
			      <div class="col-lg-10 col-sm-12">
				      <div class="alert alert-warning border-warning">
					      <h4 class="alert-heading text-black">
						      PayPal Service Issue
					      </h4>
					      <hr/>
					      <p class="mb-0 text-black">
						      Due to a PayPal service disruption we are currently unable to accept PayPal payments. We are working with PayPal to resolve the issue.<br/>
						      <br/>
						      We are sorry for the inconvenience.
					      </p>
				      </div>
			      </div>
		      </template>

		      </div>
	      </template>

        <div class="row justify-content-center">
          <div class="col-lg-10 col-sm-12">
            <div class="card card-body">
              <div class="table-responsive mb-0">
                <b-table :items="invoices" :fields="fields" responsive="sm" :per-page="10" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">

                  <template #cell(state)="data">
                    <template v-if="data.item.state === InvoiceState.CANCELLED">
                      <span class="badge badge-secondary">
                        {{$t('billing.invoices.state.cancelled')}}
                      </span>
                    </template>
                    <template v-else-if="data.item.state === InvoiceState.DUE">
                      <span class="badge badge-warning">
                        {{$t('billing.invoices.state.due')}}
                      </span>
                    </template>
                    <template v-else-if="data.item.state === InvoiceState.DUNNED">
                      <span class="badge badge-secondary">
                        {{$t('billing.invoices.state.dunned')}}
                      </span>
                    </template>
                    <template v-else-if="data.item.state === InvoiceState.DUNNING_FAILED">
                      <span class="badge badge-secondary">
                        {{$t('billing.invoices.state.dunned')}}
                      </span>
                    </template>
                    <template v-else-if="data.item.state === InvoiceState.INVALID">
                      <span class="badge badge-default">
                        {{$t('billing.invoices.state.invalid')}}
                      </span>
                    </template>
                    <template v-else-if="data.item.state === InvoiceState.NONCOLLECTIBLE">
                      <span class="badge badge-secondary">
                        {{$t('billing.invoices.state.noncollectible')}}
                      </span>
                    </template>
                    <template v-else-if="data.item.state === InvoiceState.OPEN">
                      <span class="badge badge-primary">
                        {{$t('billing.invoices.state.open')}}
                      </span>
                    </template>
                    <template v-else-if="data.item.state === InvoiceState.OVERDUE">
                      <span class="badge badge-danger">
                        {{$t('billing.invoices.state.overdue')}}
                      </span>
                    </template>
                    <template v-else-if="data.item.state === InvoiceState.PAID">
                      <span class="badge badge-success">
                        {{$t('billing.invoices.state.paid')}}
                      </span>
                    </template>
                    <template v-else-if="data.item.state === InvoiceState.REFUNDED">
                      <span class="badge badge-info">
                        {{$t('billing.invoices.state.refunded')}}
                      </span>
                    </template>
                    <template v-else-if="data.item.state === InvoiceState.INTRANSIT">
                      <span class="badge badge-success">
                        {{$t('billing.invoices.state.transit')}}
                      </span>
                    </template>
                  </template>

                  <template #cell(invoice_id)="data">
                    <b>
                      CF-{{ data.item.invoice_id }}
                    </b>
                  </template>

                  <template #cell(amount)="data">
                    {{ new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(data.item.amount) }}
                  </template>

                  <template #cell(charged)="data">
	                  <!--  && ![InvoiceState.DUNNING_FAILED, InvoiceState.INVALID].includes(data.item.state) -->
                    <template v-if="data.item.amount > 0">
                      <template v-if="data.item.charge.status">
                        {{ $t('billing.invoices.charge.processed', {date: $d(parseDate(data.item.charge.date), 'datetime', getDTLocale())}) }}
                      </template>
                      <template v-else>
                        <i>
                          {{$t('billing.invoices.charge.pending')}}
                        </i>
                      </template>
                    </template>
                    <template v-else>
                      - - -
                    </template>
                  </template>

                  <template #cell(fulfillment)="data">
                    <template v-if="data.item.amount > 0">
                      <template v-if="data.item.fulfillment.status">
                        {{ $t('billing.invoices.fulfillment.processed', {date: $d(parseDate(data.item.fulfillment.date), 'datetime', getDTLocale())}) }}
                      </template>
                      <template v-else>
                        <i>
                          {{$t('billing.invoices.fulfillment.pending')}}
                        </i>
                      </template>
                    </template>
                    <template v-else>
                        - - -
                    </template>
                  </template>

                  <template #cell(document)="data">
                    <template v-if="data.item.document">
                      <a :href="`${apiBase}v1/invoice/${data.item.invoice_id}/download`" target="_blank">
                        <button class="btn btn-primary btn-sm btn-rounded">
                          {{$t('billing.invoices.document')}}
                        </button>
                      </a>
                    </template>
                    <template v-else>
                      <i>
                        No document
                      </i>
                    </template>
                  </template>

                  <template #cell(created_at)="data">
                    {{ $d(parseDate(data.item.created_at), 'datetime', getDTLocale()) }}
                  </template>

                  <template #cell(actions)="data">
                    <template v-if="[InvoiceState.OPEN, InvoiceState.DUE, InvoiceState.OVERDUE, InvoiceState.DUNNED].includes(data.item.state)">
                      <button class="btn btn-primary btn-pulse btn-sm btn-rounded" v-on:click="invokeInvoiceSettlements()" :disabled="processing">
                        <template v-if="processing">
                          <half-circle-spinner
                              :animation-duration="900"
                              :size="14"
                              class="align-middle d-inline-block"
                          />
                        </template>
                        <template v-else>
                          <i class="fad fa-money-check"/>
                        </template>

                        {{$t('billing.invoices.settle.button')}}
                      </button>
                    </template>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                    <button v-b-modal.csvModal class="btn btn-primary btn-sm btn-rounded" style="max-height: 31px; position: absolute; bottom: 13px">
                      {{$t('billing.invoices.csv.button')}}
                    </button>
                </div>
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :per-page="10"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--    CSV Constructor.-->
              <b-modal
                  ref="csvConstructor"
                  :title="$t('billing.invoices.csv.title')"
                  title-class="font-18"
                  hide-footer
                  size="lg"
                  id="csvModal"
              >
                <form class="container">
                  <!--                Invoices time frame.-->
                  <label for="timeframe">
                    <strong>{{$t('billing.invoices.csv.timeframe_label')}}</strong>
                  </label>
                  <div class="row-cols-3 mb-3" id="timeframe">
                    <div class="col">
                      <label class="col-form-label" for="dateFrom">{{$t('billing.invoices.csv.date_from')}}</label>
                      <input type="date" id="dateFrom" v-model="csvTimeframe.startDate" class="form-control"
                             :min="csvTimeframe.defaultStartDate"
                             :max="csvTimeframe.defaultEndDate"
                             required
                      >
                    </div>
                    <div class="col">
                      <label class="col-form-label" for="dateTo">{{$t('billing.invoices.csv.date_to')}}</label>
                      <input type="date" id="dateTo" v-model="csvTimeframe.endDate" class="form-control"
                             :min="csvTimeframe.defaultStartDate"
                             :max="csvTimeframe.defaultEndDate"
                             required
                      >
                    </div>
                  </div>
                  <!--                Invoices timeframe end.-->
                  <label for="detailsSelect">
                    <strong>{{$t('billing.invoices.csv.details_label')}}</strong>
                  </label>
                  <div class="input-group mb-3" id="detailsSelect">
                    <template v-for="detail in csvDetails">
                      <span class="form-check-inline col-md-3" :key="detail.id">
                        <input type="checkbox" v-model="detail.value" :id="detail.id" class="form-check-input">
                        <label class="form-check-label" :for="detail.id">{{$t(detail.label)}}</label>
                      </span>
                    </template>
                  </div>
                  <div class="d-flex flex-row-reverse">
                      <div>
                        <button @click="$bvModal.hide('csvModal')" type="button" class="btn btn-secondary btn-sm btn-rounded mr-2">
                          {{$t('billing.invoices.csv.cancel')}}
                        </button>
                        <button class="btn btn-primary btn-sm btn-rounded" type="button" v-on:click="downloadCSV">
                          <half-circle-spinner
                              v-if="exportInProgress"
                              :animation-duration="1200"
                              :size="16"
                              class="align-middle d-inline-block"
                          />
                          {{$t('billing.invoices.csv.download_csv')}}
                        </button>
                      </div>
                  </div>
                </form>
              </b-modal>
              <!--   CSV Constructor end.-->
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="margin: auto; width: 80px;">
        <half-circle-spinner
            :animation-duration="2000"
            :size="80"
            color="white"
            class="align-middle"
        />
      </div>
    </template>
  </div>
</template>
